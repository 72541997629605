/**
 * @file Entrypoint for surface page.
 */
import { setOauthToken as setJqueryAjaxOauthToken } from '@@/bits/ajax'
import { observeConnectivity } from '@@/bits/connectivity'
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import {
  captureException,
  captureMessage,
  captureNonNetworkFetchError,
  isNetworkFetchError,
  setScope,
} from '@@/bits/error_tracker'
import { isAppUsing, isDebugMode } from '@@/bits/flip'
import window from '@@/bits/global'
import { captureOnlyWhitelistedClicks } from '@@/bits/pepin'
import { setTimezoneCookie } from '@@/bits/timezone'
import { isStringTruthy } from '@@/bits/truthy'
import { PageReloadableError, setupVueApp } from '@@/bits/vue'
import { install as installNativeBridge } from '@@/native_bridge/install'
import { usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import { fetchJson } from '@@/surface/api_fetch'
import PadletApi from '@@/surface/padlet_api'
import { trackPrinting } from '@@/surface/printing'
import type { Library, LibraryId } from '@@/types'
import Surface from '@@/vuecomponents/Surface.vue'
import { useSurfaceUrlHashShortcuts } from '@@/vuecomposables/surface_url_hash_shortcuts'
import { blankState, setupNativeBridgeXstore, storeStructure } from '@@/vuexstore/surface/index'
import { initialPostActionState, initialSurfaceState } from '@@/vuexstore/surface/initial_state'
import type { RootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { configure as configureArvo } from '@padlet/arvo'
import { FetchError, fetchResponse } from '@padlet/fetch'
import { FetchOptionCredentials, FetchOptionMode, HTTPMethod } from '@padlet/fetch/src/types'
import { installTranslations, MENTION_ELEMENT_TAG_NAME, PdltMention } from '@padlet/universal-post-editor'
import type { Pinia } from 'pinia'

setTimezoneCookie()

if (isDebugMode) {
  import('@@/bits/perf')
    .then(({ default: trackMeasurement }) => {
      trackMeasurement()
    })
    .catch(captureException)
}

let startingStatePath
let preloadedStartingState

// Some data are too big/slow to be serialized so we don't put them in vueStartingState
// We will fetch them here. User will see the Padlet background loaded and then the content loaded after a while instead of waiting a long time with a blank page
async function loadData(): Promise<void> {
  // preloadedStartingState is preloaded (see show.html.erb), this should just get the preloaded data
  startingStatePath = document.getElementById('starting-state-preload')?.getAttribute('href')
  if (typeof startingStatePath !== 'string') {
    captureMessage('Surface startingStatePath absent')
    return
  }

  try {
    // for browsers that do not support preload, it will run a normal fetch request
    // NOTE: cors doesn't seem to work on Safari: https://stackoverflow.com/questions/52635660/can-link-rel-preload-be-made-to-work-with-fetch/63814972#63814972
    // NOTE 2: some browsers are strict about headers and will say the preload "is not used because the request headers do not match"
    //         => just use fetchResponse here instead of fetchJson with custom headers
    const preloadedStartingStateResponse = await fetchResponse(startingStatePath, {
      method: HTTPMethod.get,
      // preload send cookies with its request for resources from the same origin
      credentials: FetchOptionCredentials.include,
      // default mode is same-origin, preload uses no-cors
      mode: FetchOptionMode.noCors,
    })
    preloadedStartingState = await preloadedStartingStateResponse.json()
  } catch (error) {
    if (error instanceof FetchError) {
      const isExpiredToken = error.status === 410
      if (!isExpiredToken && !isNetworkFetchError(error)) captureException(error, { context: { startingStatePath } })
    } else {
      captureException(error, { context: { startingStatePath } })
    }

    // If the fetch fails and preloadedStartingState is absent, the page can't function. So, reload it.
    throw new PageReloadableError()
  }

  try {
    const { arvoConfig } = preloadedStartingState

    // Configure Arvo, ajax, ApiFetch
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
    const isBeta: boolean = isStringTruthy(urlParams.get('beta')) && preloadedStartingState.user.role === 'admin'
    arvoConfig.isBeta = isBeta
    setJqueryAjaxOauthToken(arvoConfig?.token?.oauthToken)

    arvoConfig.jsonApiVersion = 6

    await configureArvo(arvoConfig)
  } catch (error) {
    captureException(error, { context: { startingStatePath } })
  }
}

let memoizedStartingState: RootState | null = null

function generateStartingState(): RootState {
  if (memoizedStartingState != null) return memoizedStartingState
  // Get blocked content providers
  const blockedContentProviders = new Set<string>(
    (preloadedStartingState.tenant?.blocked_content_providers as string[] | undefined) ?? [],
  )
  // init store
  const startingState: RootState = {
    ...blankState(),
    ...initialSurfaceState(),
    blockedContentProviders,
    postAction: preloadedStartingState.isSlideshow === true ? {} : initialPostActionState(),
    ...preloadedStartingState,
  }
  memoizedStartingState = startingState
  return startingState
}

function initializeVuexStore(store): void {
  try {
    setupNativeBridgeXstore(store)

    // Inform state when the browser goes offline/online
    observeConnectivity((isOnline: boolean): void => {
      void store.dispatch('changeConnectivity', { isOnline })
    })

    // install older native brige methods in the window object
    installNativeBridge(window, store)

    const startingState = generateStartingState()
    void store.dispatch('initializeState', startingState)
    void store.dispatch('realtime/connect')
    setScope({ userId: store.state.user.id })

    // event tracking
    captureOnlyWhitelistedClicks()
  } catch (error) {
    captureException(error, { context: { startingStatePath } })
  }
}

function genericFetchError(payload: { error: any; source: string }): void {
  captureNonNetworkFetchError(payload.error, { source: payload.source })
}

async function fetchUserLibraries(): Promise<Record<number, Library>> {
  let librariesById: Record<number, Library> = {}
  try {
    const response = await fetchJson(`/api/1/accounts`)
    const { data } = response
    data.forEach((account) => {
      if (account.type === 'library') {
        librariesById = { ...librariesById, [account.id]: account.attributes }
      }
    })
  } catch (error) {
    genericFetchError({ error, source: 'SurfaceFetchUserLibraries' })
  }
  return librariesById
}

async function fetchWallCreatableLibraries(userId: number): Promise<LibraryId[]> {
  let wallCreatableLibraryIds: LibraryId[] = []
  try {
    const response = await PadletApi.Library.fetchCreatableAndVisibleLibraries({ userId })
    const nodes = response.data as Array<JsonAPIResource<Library>>
    const libraries = nodes.map((record) => record.attributes)
    const ids = libraries.map((library) => library.id)
    wallCreatableLibraryIds = ids
  } catch (error) {
    genericFetchError({ error, source: 'SurfaceFetchWallCreatableLibraries' })
  }

  return wallCreatableLibraryIds
}

function initializePiniaStore(_store: Pinia): void {
  const startingState = generateStartingState()
  useSurfaceStartingStateStore().intializeState(startingState)
  if (startingState?.amIOwner) {
    void import('@@/pinia/experiments').then(({ useExperimentsStore }) => {
      useExperimentsStore().setUpgradeModalCopyAExperimentVariant(startingState.upgradeModalCopyAExperimentVariant)
      useExperimentsStore().setUpgradeModalSingleStepExperimentVariant(
        startingState.upgradeModalSingleStepExperimentVariant,
      )
      void usePersonalPlansStore().initialize()
    })
  }
  if (startingState.isWallTransferable || startingState.canIRemake) {
    void Promise.all([
      fetchUserLibraries(),
      fetchWallCreatableLibraries(startingState.user.id),
      import('@@/pinia/surface_wall_transfer_store'),
      import('@@/pinia/surface_remake_panel'),
    ]).then((response) => {
      const librariesById = response[0]
      const wallCreatableLibraryIds = response[1]
      const { useSurfaceWallTransferStore } = response[2]
      const { useSurfaceRemakePanelStore } = response[3]

      useSurfaceWallTransferStore().initialize({
        librariesById,
        wallCreatableLibraryIds,
      })
      void useSurfaceRemakePanelStore().initializeStore({
        librariesById,
        wallCreatableLibraryIds,
      })
    })
  }
}

async function addCommands(): Promise<void> {
  if (isAppUsing('showCommandPalette')) {
    const [bitsCommands, surfaceCommandsComposable] = await Promise.all([
      import('@@/bits/commands'),
      import('@@/vuecomposables/surface_commands'),
    ])
    await bitsCommands.injectCommands(surfaceCommandsComposable.useSurfaceCommands().commands)
  }
}

const beforeMountPromises: Array<Promise<any>> = [loadData()]
if (isAppUsing('showCommandPalette')) {
  beforeMountPromises.push(addCommands())
}
// Need to install translations for the post editor before it's rendered.
// We do it here so it's installed the earliest possible.
beforeMountPromises.push(installTranslations(getHyphenatedCurrentLocale()))

setupVueApp({
  el: '#app',
  usePinia: true,
  rootComponent: Surface,
  beforeMountPromises,
  storeOptions: storeStructure,
  initializeStore: initializeVuexStore,
  initializePiniaStore,
  disableAccessibilityChecks: true,
  onGlobalLogin: async () => {
    await useSurfaceGuestStore().handleLogIn()
  },
  onGlobalLogout: async () => {
    await useSurfaceGuestStore().handleLogOut()
  },
})
  .then(() => {
    // Track when user tries to print document using Ctrl+P
    trackPrinting()
    window.customElements.define(MENTION_ELEMENT_TAG_NAME, PdltMention)
    useSurfaceUrlHashShortcuts()
  })
  .catch((e) => captureException(e))
